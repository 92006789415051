/* eslint-disable jsx-a11y/anchor-is-valid */
import ContactForm from './components/ContactForm';
import OurCustomers from './components/OurCustomers';

function App() {
  return (
    <div>
      <header id="header" className="fixed-top">
        <div className="container">
          <div className="logo float-left">
            {/* Uncomment below if you prefer to use an image logo */}
            {/* <h1 class="text-light"><a href="#header"><span>Innovateam</span></a></h1> */}
            <a href="#intro" className="scrollto">
              <img
                src="img/tailored/logo_white_background.jpg"
                alt=""
                className="img-fluid"
              />
            </a>
          </div>
          <nav className="main-nav float-right d-none d-lg-block">
            <ul>
              <li className="active">
                <a href="#intro">Home</a>
              </li>
              <li>
                <a href="#about">About Us</a>
              </li>
              <li>
                <a href="#why-us">Services</a>
              </li>
              <li>
                <a href="#portfolio">Customers</a>
              </li>
              <li>
                <a href="#contact">Contact Us</a>
              </li>
              {/* <li class="drop-down">
                <a href="">Language</a>
                <ul>
                  <li>
                    <a href="#">
                        <i class="fa fa-check"></i> English
                    </a>
                  </li>
                  <li>
                    <a href="#">Русский</a>
                  </li>
                </ul>
              </li> */}
              {/* <li><a href="#team">Team</a></li> */}
              {/* <li class="drop-down">
                <a href="">Drop Down</a>
                <ul>
                  <li><a href="#">Drop Down 1</a></li>
                  <li class="drop-down">
                    <a href="#">Drop Down 2</a>
                    <ul>
                      <li><a href="#">Deep Drop Down 1</a></li>
                      <li><a href="#">Deep Drop Down 2</a></li>
                      <li><a href="#">Deep Drop Down 3</a></li>
                      <li><a href="#">Deep Drop Down 4</a></li>
                      <li><a href="#">Deep Drop Down 5</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Drop Down 3</a></li>
                  <li><a href="#">Drop Down 4</a></li>
                  <li><a href="#">Drop Down 5</a></li>
                </ul>
              </li> */}
            </ul>
          </nav>
          {/* .main-nav */}
        </div>
      </header>
      {/* #header */}
      {/*==========================
          Intro Section
        ============================*/}
      <section id="intro" className="clearfix">
        <div className="container">
          <div className="intro-img">
            <img src="img/intro-img2.png" alt="" className="img-fluid" />
          </div>
          <div className="intro-info">
            <h2>
              Innovating
              <br />
              tailored solutions
              <br />
              for your business
            </h2>
            <div>
              <a href="#contact" className="btn-services scrollto">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* #intro */}
      <main id="main">
        {/*==========================
            About Us Section
          ============================*/}
        <section id="about">
          <div className="container">
            <header className="section-header">
              <h3>About Us</h3>
              <p>
                We are a team of experienced developers, dedicated to designing
                and building the right solution for your business
              </p>
            </header>
            <div className="row about-container">
              <div className="col-lg-6 content order-lg-1 order-2">
                <p></p>
                <p></p>
                <div className="icon-box wow">
                  <div className="icon">
                    <i className="fa fa-microchip" />
                  </div>
                  <h4 className="title">Startup mindset</h4>
                  <p className="description">
                    Vast experience in building products for startups from early
                    stage POCs to production
                  </p>
                </div>
                <div className="icon-box wow" data-wow-delay="0.2s">
                  <div className="icon">
                    <i className="fa fa-briefcase" />
                  </div>
                  <h4 className="title">Custom made solutions</h4>
                  <p className="description">
                    We build custom software using the latest technologies to
                    meet your business' needs
                  </p>
                </div>
                <div className="icon-box wow" data-wow-delay="0.4s">
                  <div className="icon">
                    <i className="fa fa-shield" />
                  </div>
                  <h4 className="title">Security oriented development</h4>
                  <p className="description">
                    As IDF Intelligence corps alumni, we know how valuable your
                    data is and how to keep it secured
                  </p>
                </div>
                <div className="icon-box wow" data-wow-delay="0.6s">
                  <div className="icon">
                    <i className="fa fa-cloud" />
                  </div>
                  <h4 className="title">Cloud based infrastructure</h4>
                  <p className="description">
                    Proved hands-on experience in building cloud based
                    applications allows us to reduce development costs and
                    time-to-market
                  </p>
                </div>
              </div>
              <div className="col-lg-6 background order-lg-2 order-1 wow">
                <img src="img/why-us.png" className="img-fluid" alt="" />
              </div>
            </div>
            {/* <div className="row about-extra">
              <div className="col-lg-6 wow fadeInUp">
                <img src="img/about-extra-1.svg" className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
                <h4>
                  Voluptatem dignissimos provident quasi corporis voluptates sit
                  assumenda.
                </h4>
                <p>
                  Ipsum in aspernatur ut possimus sint. Quia omnis est occaecati
                  possimus ea. Quas molestiae perspiciatis occaecati qui rerum.
                  Deleniti quod porro sed quisquam saepe. Numquam mollitia
                  recusandae non ad at et a.
                </p>
                <p>
                  Ad vitae recusandae odit possimus. Quaerat cum ipsum corrupti.
                  Odit qui asperiores ea corporis deserunt veritatis quidem
                  expedita perferendis. Qui rerum eligendi ex doloribus quia
                  sit. Porro rerum eum eum.
                </p>
              </div>
            </div>
            <div className="row about-extra">
              <div className="col-lg-6 wow fadeInUp order-1 order-lg-2">
                <img src="img/about-extra-2.svg" className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
                <h4>
                  Neque saepe temporibus repellat ea ipsum et. Id vel et quia
                  tempora facere reprehenderit.
                </h4>
                <p>
                  Delectus alias ut incidunt delectus nam placeat in
                  consequatur. Sed cupiditate quia ea quis. Voluptas nemo qui
                  aut distinctio. Cumque fugit earum est quam officiis numquam.
                  Ducimus corporis autem at blanditiis beatae incidunt sunt.
                </p>
                <p>
                  Voluptas saepe natus quidem blanditiis. Non sunt impedit
                  voluptas mollitia beatae. Qui esse molestias. Laudantium
                  libero nisi vitae debitis. Dolorem cupiditate est perferendis
                  iusto.
                </p>
                <p>
                  Eum quia in. Magni quas ipsum a. Quis ex voluptatem inventore
                  sint quia modi. Numquam est aut fuga mollitia exercitationem
                  nam accusantium provident quia.
                </p>
              </div>
            </div> */}
          </div>
        </section>
        {/* #about */}
        {/*==========================
            Services Section
          ============================*/}
        {/* <section id="services" className="section-bg">
          <div className="container">
            <header className="section-header">
              <h3>Services</h3>
              <p>We provide the necessary services for your business</p>
            </header>
            <div className="row">
              <div
                className="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp"
                data-wow-duration="1.4s"
              >
                <div className="box">
                  <div className="icon">
                    <i
                      className="ion-ios-analytics-outline"
                      style={{ color: '#ff689b' }}
                    />
                  </div>
                  <h4 className="title">
                    <a href="">Deveolping serverless solutions</a>
                  </h4>
                  <p className="description">
                    Finding the right technology for your projects
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-5 wow bounceInUp"
                data-wow-duration="1.4s"
              >
                <div className="box">
                  <div className="icon">
                    <i
                      className="ion-ios-bookmarks-outline"
                      style={{ color: '#e9bf06' }}
                    />
                  </div>
                  <h4 className="title">
                    <a href="">Architectural Design</a>
                  </h4>
                  <p className="description">
                    Designing a solution for your any need.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp"
                data-wow-delay="0.1s"
                data-wow-duration="1.4s"
              >
                <div className="box">
                  <div className="icon">
                    <i
                      className="ion-ios-paper-outline"
                      style={{ color: '#3fcdc7' }}
                    />
                  </div>
                  <h4 className="title">
                    <a href="">Building fully featured solution</a>
                  </h4>
                  <p className="description" />
                </div>
              </div>
              <div
                className="col-md-6 col-lg-5 wow bounceInUp"
                data-wow-delay="0.1s"
                data-wow-duration="1.4s"
              >
                <div className="box">
                  <div className="icon">
                    <i
                      className="ion-ios-speedometer-outline"
                      style={{ color: '#41cf2e' }}
                    />
                  </div>
                  <h4 className="title">
                    <a href="">Backend Analytics for management and IT</a>
                  </h4>
                  <p className="description">
                    Providing reports and analytics that are crucial for both
                    existing and new solutions,
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp"
                data-wow-delay="0.2s"
                data-wow-duration="1.4s"
              >
                <div className="box">
                  <div className="icon">
                    <i
                      className="ion-ios-world-outline"
                      style={{ color: '#d6ff22' }}
                    />
                  </div>
                  <h4 className="title">
                    <a href="">
                      Systems permeability and viability examination
                    </a>
                  </h4>
                  <p className="description">
                    Examination of current systems, checking
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-5 wow bounceInUp"
                data-wow-delay="0.2s"
                data-wow-duration="1.4s"
              >
                <div className="box">
                  <div className="icon">
                    <i
                      className="ion-ios-clock-outline"
                      style={{ color: '#4680ff' }}
                    />
                  </div>
                  <h4 className="title">
                    <a href="">Upgrading existing infrastructure</a>
                  </h4>
                  <p className="description" />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* #services */}
        {/*==========================
            Why Us Section
          ============================*/}
        <section id="why-us" className="wow">
          <div className="container">
            <header className="section-header">
              <h3>Services</h3>
              <p>We provide the necessary services for your business.</p>
            </header>
            <div className="row row-eq-height justify-content-center">
              <div className="col-lg-4 mb-4">
                <div className="card wow">
                  <i className="fa fa-drafting-compass" />
                  <div className="card-body">
                    <h5 className="card-title">Architectural design</h5>
                    <p className="card-text">
                      Advising product owners on how to plan and select the
                      right technologies to develop their products
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="card wow ">
                  <i className="fa fa-code" />
                  <div className="card-body">
                    <h5 className="card-title">Development</h5>
                    <p className="card-text">
                      Building scalable and sustainable mobile, web and
                      enterprise applications as well as custom APIs
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="card wow ">
                  <i className="fa fa-terminal" />
                  <div className="card-body">
                    <h5 className="card-title">Integration</h5>
                    <p className="card-text">
                      Professionally integrating the finished product into your
                      business' systems, keeping your data safe when connecting
                      to third party services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*==========================
            Clients Section
          ============================*/}
        {/* <section id="testimonials" class="section-bg">
          <div class="container">
            <header class="section-header">
              <h3>Testimonials</h3>
            </header>

            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="owl-carousel testimonials-carousel wow fadeInUp">
                  <div class="testimonial-item">
                    <img src="img/testimonial-1.jpg" class="testimonial-img" alt="" />
                    <h3>Saul Goodman</h3>
                    <h4>Ceo &amp; Founder</h4>
                    <p>
                      Proin iaculis purus consequat sem cure digni ssim donec porttitora entum
                      suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et.
                      Maecen aliquam, risus at semper.
                    </p>
                  </div>

                  <div class="testimonial-item">
                    <img src="img/testimonial-2.jpg" class="testimonial-img" alt="" />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      Export tempor illum tamen malis malis eram quae irure esse labore quem cillum
                      quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat
                      irure amet legam anim culpa.
                    </p>
                  </div>

                  <div class="testimonial-item">
                    <img src="img/testimonial-3.jpg" class="testimonial-img" alt="" />
                    <h3>Jena Karlis</h3>
                    <h4>Store Owner</h4>
                    <p>
                      Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem
                      veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis
                      sint minim.
                    </p>
                  </div>

                  <div class="testimonial-item">
                    <img src="img/testimonial-4.jpg" class="testimonial-img" alt="" />
                    <h3>Matt Brandon</h3>
                    <h4>Freelancer</h4>
                    <p>
                      Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim
                      fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore
                      quem dolore labore illum veniam.
                    </p>
                  </div>

                  <div class="testimonial-item">
                    <img src="img/testimonial-5.jpg" class="testimonial-img" alt="" />
                    <h3>John Larson</h3>
                    <h4>Entrepreneur</h4>
                    <p>
                      Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor
                      noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam
                      esse veniam culpa fore nisi cillum quid.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* #testimonials */}
        {/*==========================
            Team Section
          ============================*/}
        {/* <section id="team" class="section-bg">
              <div class="container">
                <div class="section-header">
                  <h3>The Team</h3>
                  <p>
                    A bunch of geneticaly engineered mecha-monkeys, ready to answer your prayers
                  </p>
                </div>

                <div class="row">
                  <div class="col-lg-4 col-md-6 wow fadeInUp">
                    <div class="member">
                      <img src="img/team-1.jpg" class="img-fluid" alt="" />
                      <div class="member-info">
                        <div class="member-info-content">
                          <h4>Ari Zellner</h4>
                          <span>CEO</span>
                          <div class="social">
                            <a href="https://www.linkedin.com/in/ari-zellner-0b811688/"><i class="fa fa-linkedin"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="member">
                      <img src="img/team-2.jpg" class="img-fluid" alt="" />
                      <div class="member-info">
                        <div class="member-info-content">
                          <h4>Felix Razykov</h4>
                          <span>COO</span>
                          <div class="social">
                            <a href="https://www.linkedin.com/in/felix-razykov/"><i class="fa fa-linkedin"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                    <div class="member">
                      <img src="img/team-3.jpg" class="img-fluid" alt="" />
                      <div class="member-info">
                        <div class="member-info-content">
                          <h4>Dvir Malka</h4>
                          <span>CTO</span>
                          <div class="social">
                            <a href="https://www.linkedin.com/in/malkadvir"><i class="fa fa-linkedin"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
        {/* #team */}
        {/*==========================
            Clients Section
          ============================*/}
        {/* <section id="clients" class="section-bg">
              <div class="container">
                <div class="section-header">
                  <h3>Our CLients</h3>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                    dere santome nida.
                  </p>
                </div>

                <div class="row no-gutters clients-wrap clearfix wow fadeInUp">
                  <div class="col-lg-3 col-md-4 col-xs-6">
                    <div class="client-logo">
                      <img src="img/clients/client-1.png" class="img-fluid" alt="" />
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-4 col-xs-6">
                    <div class="client-logo">
                      <img src="img/clients/client-2.png" class="img-fluid" alt="" />
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-4 col-xs-6">
                    <div class="client-logo">
                      <img src="img/clients/client-3.png" class="img-fluid" alt="" />
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-4 col-xs-6">
                    <div class="client-logo">
                      <img src="img/clients/client-4.png" class="img-fluid" alt="" />
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-4 col-xs-6">
                    <div class="client-logo">
                      <img src="img/clients/client-5.png" class="img-fluid" alt="" />
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-4 col-xs-6">
                    <div class="client-logo">
                      <img src="img/clients/client-6.png" class="img-fluid" alt="" />
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-4 col-xs-6">
                    <div class="client-logo">
                      <img src="img/clients/client-7.png" class="img-fluid" alt="" />
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-4 col-xs-6">
                    <div class="client-logo">
                      <img src="img/clients/client-8.png" class="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
        <OurCustomers />
        <ContactForm />
      </main>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-4 col-md-6 footer-info"> */}
              <div className="col-12 text-center footer-info">
                <h3>Innovateam</h3>
                <h5>Software solutions</h5>
              </div>
              {/* <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">About us</a>
                  </li>
                  <li>
                    <a href="#">Services</a>
                  </li>
                  <li>
                    <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <a href="#">Privacy policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-contact">
                <h4>Contact Us</h4>
                <p>
                  <strong>Email 1:</strong> felix@innovateam.co
                  <br />
                  <strong>Email 2:</strong> dvir@innovateam.co
                  <br />
                  <strong>Phone 1:</strong> +972544710690
                  <br />
                  <strong>Phone 2:</strong> +972547271228
                  <br />
                  <strong>Address:</strong> P.B. 80, Neve Eitan, IL 1084000
                </p>
                <div className="social-links">
                  <a href="#" className="twitter">
                    <i className="fa fa-twitter" />
                  </a>
                  <a href="#" className="facebook">
                    <i className="fa fa-facebook" />
                  </a>
                  <a href="#" className="instagram">
                    <i className="fa fa-instagram" />
                  </a>
                  <a href="#" className="google-plus">
                    <i className="fa fa-google-plus" />
                  </a>
                  <a href="#" className="linkedin">
                    <i className="fa fa-linkedin" />
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            © Copyright <strong>Innovateam</strong>. All Rights Reserved
          </div>
        </div>
      </footer>
      {/* #footer */}
      <a onClick={() => window.scrollTo(0, 0)} className="back-to-top">
        <i className="fa fa-chevron-up" />
      </a>
      {/* Uncomment below if you want to use a preloader */}
      {/* <div id="preloader"></div> */}
    </div>
  );
}

export default App;
